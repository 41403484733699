$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.filterListItemCheckbox {
  margin: $spacing-3 0 0 0;
}

.filterListItemCheckboxLabel {
  text-transform: capitalize;
}
