$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.filterList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-2;
}

.filterButton {
  width: 36px;
  padding: $spacing-2;

  // Double size of the two buttons with spacing
  &.autoSize {
    width: 2 * 36px + $spacing-2;
  }

  &.selected {
    background-color: $lights-high;
    span {
      color: $background-dim;
    }
  }
}