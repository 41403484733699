$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$circle-icon-size: 34px;
$check-icon-size: 15px;

.colorPickerWrapper {
  display: grid;
  gap: $spacing-3;
  grid-template-columns: repeat(auto-fit, $circle-icon-size);

  &.fillFullWidth {
    grid-template-columns: repeat(auto-fit, minmax($circle-icon-size, 1fr));
  }
}

.colorPickerItem {
  width: $circle-icon-size;
  height: $circle-icon-size;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: $border-1 solid $border-bright;

  &:hover,
  &:focus {
    outline: none;
  }

  &:not(.disabled) {
    &:hover,
    &:focus {
      cursor: pointer;
    }

    &:hover,
    &[aria-checked="true"] {
      border: $border-3 solid $accent-default;

      .icon {
        display: block;
        font-size: 15px;

        path {
          color: $accent-default;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 33.5px;
      height: 4px;
      background-color: $background-light;
      border-radius: 3px;
      transform: rotate(-45deg);
      z-index: 0;
    }
  }
}

.icon {
  display: none;
}
