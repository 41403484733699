$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: none;
  position: relative;
  width: 100%;
  padding: $spacing-3;
  min-width: 275px;
  border-radius: $radius-1;
  overflow: hidden;
  z-index: 4;

  @media screen and (min-width: $breakpoint_l) {
    position: absolute;
    top: 66px;
    right: 0;
    padding: $spacing-3;
    border: $border-default;
    background-color: $background-light;
  }

  &.visible {
    display: block;
  }

  .itemLabelWrapper {
    height: 24px;
    padding: $spacing-2 $spacing-3;

    @media screen and (min-width: $breakpoint_l) {
      height: 40px;
    }
  }

  .itemLabel {
    @include text-body2Regular;

    @media screen and (min-width: $breakpoint_l) {
      @include text-body1Regular16;
    }

    &:hover,
    &:focus {
      @media screen and (min-width: $breakpoint_l) {
        background-color: $background-light;
      }
    }

    &.checked {
      @include text-body2Medium;
      @media screen and (min-width: $breakpoint_l) {
        @include text-body1Medium16;
      }
    }
  }
}
