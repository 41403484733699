$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.filterDateRangeContainer {
  overflow: visible;
  background-color: $background-dim;
}

.filterDateRangeWrapper {
  margin: 0 0 16px;

  .filterDateRange {
    padding: 8px 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border: $border-1 solid $border-default;
    color:$lights-medium;

    //@include text-sm-medium;
    @include text-subtitle2;

    .filterDateRangeToggle {
      margin: 0 0 0 10px;
      width: auto;

      @extend %button-reset;

      &:hover,
      &:active,
      &.active {
        > svg {
          color: gold;
        }
      }

      > svg {
        display: block;
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        color: $lights-medium;
      }
    }
  }
}

.filterDateRangeDatePicker {
  left: 50%;
  transform: translate(-50%, 10px);
}
