$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.collapseWrapper {
  &.isExpanded > {
    .collapseBody {
      display: block;
    }

    .collapseToggle {
      transform: rotate(180deg);
      transition: all 0.1s ease-in-out;
    }
  }
}

.collapseHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }

  .collapseTitle {
    overflow: hidden;
    width: 100%;
  }

  .collapseToggle {
    padding: 0;
    border: none;
    background: none;
    font-size: 0;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }

    .isExpanded & {
      transform: rotate(180deg);
      transition: all 0.1s ease-in-out;
    }
  }
}

.icon {
  color: $lights-high;

  // Scale path to be bigger as path inside icon is smaller
  path {
    transform: scale(1.3);
    transform-origin: center;
  }
}

.collapseBody {
  display: none;
  padding: $spacing-4 0 0;

  .isExpanded & {
    display: block;
  }
}

