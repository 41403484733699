$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.sliderWrapper {
  padding: 0 10px;
}

.sliderContainer {
  &[class*="rc-slider"] {
    padding: 5px 0;
    height: 66px;
  }

  [class*="rc-slider-mark"] {
    padding: $spacing-1 0 0;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    [class*="rc-slider-mark-text"] {
      transform: translateX(0) !important;
      left: 0 !important;
      color: $lights-high;
      letter-spacing: -0.2px;

      @include text-body1Regular16;

      &:first-child {
        transform: translateX(0) !important;
        left: 0 !important;
      }
      &:last-child {
        transform: translateX(0) !important;
        left: 0 !important;
      }
    }
  }

  [class*="rc-slider-handle"] {
    &,
    &:active {
      box-shadow: none !important;
      top: 9px !important;
    }
  }
}