$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.filterListItemCheckbox {
  margin: 0 0 14px;
}

.filterListItemCheckboxLabel {
  height: auto;

  @include text-base-regular;
}
