$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.filterListItemCheckbox {
  margin: 0 0 16px;
}

.filterListItemCheckboxLabel {
  height: auto;
  color: $lights-medium;
  @include text-subtitle2;
}

.test {
  background-color: $background-dim;
}