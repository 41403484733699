$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  position: relative;
  display: none;
  width: 100%;

  @media screen and (min-width: $breakpoint_m) {
    display: flex;
  }
}

.toggleButton {
  // Change the arrow icon color on hover
  &:hover {
    & .toggleArrowIcon {
      color: $background-dim;
    }
  }

  span {
    @include text-buttonL;
  }
}

.toggleTextWrapper {
  margin-right: $spacing-4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: calc(100% - 18px);
}

.toggleTitle {
  padding: 0 $spacing-1;
  color: $lights-high;
  white-space: nowrap;
}

.toggleDescription {
  color: $lights-high;

  @include text-subtitle3;
  @include inline_overflow_ellipsis();
}

.toggleArrowIcon {
  margin-top: 0;
  transition: transform ease 0.3s;
  color: $lights-high;

  &.rotate {
    transform: rotate(180deg);
  }
}
