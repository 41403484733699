$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$dots-small-size: 7px;
$dots-medium-size: 10px;

.infiniteScrollLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;

  &.size {
    &-small {
      padding-top: 24px;
    }

    &-medium {
      padding-top: 50px;
    }
  }
}

.infiniteScrollLoaderDots {
  position: relative;
  border-radius: 5px;
  background-color: $accent-default;
  color: $accent-default;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    border-radius: 5px;
    background-color: $accent-default;
    color: $accent-default;
  }

  &::after {
    border-radius: 5px;
    background-color: $accent-default;
    color: $accent-default;
  }

  &.size {
    &-small {
      width: $dots-small-size;
      height: $dots-small-size;

      &::before {
        left: -($dots-small-size + 5px);
        width: $dots-small-size;
        height: $dots-small-size;
        animation: dotFlashing 0.7s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        left: $dots-small-size + 5px;
        width: $dots-small-size;
        height: $dots-small-size;
        animation: dotFlashing 0.7s infinite alternate;
        animation-delay: 1s;
      }
    }

    &-medium {
      width: $dots-medium-size;
      height: $dots-medium-size;

      &::before {
        left: -($dots-medium-size + 5px);
        width: $dots-medium-size;
        height: $dots-medium-size;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        left: $dots-medium-size + 5px;
        width: $dots-medium-size;
        height: $dots-medium-size;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $accent-default;
  }
  50%,
  100% {
    background-color: $accent-bright;
  }
}
