$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$scrollbar-padding: $spacing-4;

.root {
  padding: $spacing-5 $spacing-6;

  &:has(.inModal) {
    padding: 0;
  }
}

.filtersBody {
  max-height: calc(100vh - #{$header_height + 77px});
  overflow-y: auto;
  padding-right: $scrollbar-padding;

  &.inModal {
    padding-right: 0;
    max-height: unset;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.filtersFooter {
  margin-top: 28px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;

  &.inModal {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    background: $background-dim;

    .filtersFooterButton {
      margin: 0;
    }
  }
}

.filtersFooterButton {
  width: 100%;
}

.datePickerWrapper {
  position: relative;
}

.datePickerWrapperOpen {
  height: 485px;
}
