$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$filtersWidthDesktop: 376px;
$filtersHeaderHeight: 60px;

.root {
  position: relative;
  background: $background-dim;
  overflow: clip;
}

.backgroundTitleWrapper {
  position: absolute;
  top: $header_height;
  width: 100vw;
  height: 90px;
  overflow-x: hidden;

  @media screen and (min-width: $breakpoint_l) {
    top: $header_height;
    height: 226px;
  }
}

.centeredBackgroundTitleWrapper {
  top: 0;
}

.titleWrapper {
  margin: 0;
  padding: 40px 0 24px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 62px 0 40px;
  }

  &.centeredAthleteSorting {
    @media screen and (min-width: $breakpoint_l) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.athleteSorting {
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    top: 5px;
    display: block;
    width: 301px;
  }
}

.pageWrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.borderElement {
  display: none;
  position: absolute;
  top: 0;
  left: $filtersWidthDesktop;
  width: 1px;
  height: 100%;
  background-color: $border-default;
  z-index: 3; /* Below the aside content but above other elements */

  @media screen and (min-width: $breakpoint_l) {
    display: block;
  }
}

.asideWrapper {
  flex-grow: 1;
  flex-basis: $filtersWidthDesktop;
  align-self: start;
  position: -webkit-sticky;
  position: sticky;
  top: $header_height + $spacing-5;
  width: $filtersWidthDesktop;
  z-index: 4;
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    display: block;
  }
}

.contentWrapper {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 40%;
}

.contentHeader {
  padding: $spacing-5;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: $spacing-5;
  flex-direction: row;

  &.mobile {
    display: flex;

    &.mobilePage {
      margin-top: $header_height;
    }

    @media screen and (min-width: $breakpoint_l) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media screen and (min-width: $breakpoint_l) {
      display: flex;
      border-bottom: $border-1 solid $border-default;
    }
  }

  .sectionSearchBar {
    width: 70%;
  }

  .sectionSorting {
    justify-content: flex-end;
    width: 30%;
  }
}

.contentBody {
  margin: 0;
  padding: $spacing-5;
  width: 100%;
  position: relative;
}
