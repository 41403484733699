$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.filterSliderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px;
  position: relative;
}

.filterSliderHeaderInput {
  @include text-subtitle2;

  background-color: $background-dim;
}

.prefix {
  @include text-subtitle2;

  color: white;
}

.prefixInputWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.priceContainer {
  background-color: $background-dim;
}
