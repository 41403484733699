$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.inputWrapper {
  overflow: hidden;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 32px;
  background-color: $background-dim;
}

.input {
  @include text-subtitle2;

  width: 100%;
  padding-left: 4px;
  background: none;
  border: 0;
  color: $lights-high;
  outline: none;

  @include number_no_arrows;
}
